import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { RouterModule } from '@angular/router';
import { INDUSTRIES } from '@const/industries.const';
import { Industries } from '@interfaces/industries.interface';

@Component({
  selector: 'app-industries',
  standalone: true,
  imports: [MatIcon, ContactUsComponent, RouterModule],
  templateUrl: './industries.component.html',
  styleUrl: './industries.component.scss',
})
export class IndustriesComponent {
  public industries: Industries[] = INDUSTRIES;
}
