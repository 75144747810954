<div class="industries" autoscroll="true">
  <div class="header-main">
    <div class="header-main__text">
      <h2 i18n="@@We *span*Empower Industries*span* while *span*Driving Innovation*span*">
        We <span>Empower Industries</span>
        <br>
        while <span>Driving Innovation</span>
      </h2>
      <div>
        <p class="l normal" i18n="@@Explore the diverse industries we serve and the transformative solutions we provide. From healthcare to finance, manufacturing to retail, discover how our expertise is making an impact across diverse sectors.">Explore the diverse industries we serve and the transformative solutions we provide. From healthcare to finance, manufacturing to retail, discover how our expertise is making an impact across diverse sectors.<br></p>
      </div>
    </div>
    <div class="background">
      <div>
        <h1 i18n="@@Industries">Industries</h1>
      </div>
    </div>
  </div>
  <div class="industries-list">
    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--orange)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/finance" i18n="@@Finance & Banking">Finance & Banking</h2>
          <button routerLink="/industries/finance" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Discover how our innovative technology solutions are reshaping the finance and banking sector. From streamlined digital banking experiences to advanced risk management systems, we empower financial institutions to thrive in the digital age.">Discover how our innovative technology solutions are reshaping the finance and banking sector. From streamlined digital banking experiences to advanced risk management systems, we empower financial institutions to thrive in the digital age.<br></p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Investments">Investments</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Financial analysis">Financial analysis</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Payment systems">Payment systems</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Insurance">Insurance</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Digital platforms">Digital platforms</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Accounting">Accounting</p>
        </div>
      </div>
    </div>

    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--olive)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/healthcare" i18n="@@Healthcare & Life Sciences">Healthcare & Life Sciences</h2>
          <button routerLink="/industries/healthcare" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Empower your healthcare organization with cutting-edge technology solutions tailored for the Healthcare & Life Sciences industry, enabling seamless patient care, data management, and research advancement.">Empower your healthcare organization with cutting-edge technology solutions tailored for the Healthcare & Life Sciences industry, enabling seamless patient care, data management, and research advancement.<br></p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Medicine">Medicine</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Biology">Biology</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Pharmaceuticals">Pharmaceuticals</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Medical research">Medical research</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Medical devices">Medical devices</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Genetics">Genetics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Neuroscience">Neuroscience</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Healthy lifestyle">Healthy lifestyle</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Medical technologies">Medical technologies</p>
        </div>
      </div>
    </div>

    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--red)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/ecommerce" i18n="@@eCommerce & Retail">eCommerce & Retail</h2>
          <button routerLink="/industries/ecommerce" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Elevate your eCommerce & Retail business with innovative digital solutions that enhance customer experience, streamline operations, and drive sales growth in the competitive online marketplace.">
          Elevate your eCommerce & Retail business with innovative digital
          solutions that enhance customer experience, streamline operations, and
          drive sales growth in the competitive online marketplace.
        </p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Retail">Retail</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Online shopping">Online shopping</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Electronic payment system">Electronic payment system</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Mobile commerce">Mobile commerce</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Delivery logistics">Delivery logistics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Customer service">Customer service</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Online store">Online store</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Sales marketing">Sales marketing</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Inventory management">Inventory management</p>
        </div>
      </div>
    </div>

    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--blue)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/innovations" i18n="@@Hi-Tech & Innovations">Hi-Tech & Innovations</h2>
          <button routerLink="/industries/innovations" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Stay ahead of the curve in the fast-paced world of Hi-Tech & Innovations with our custom solutions, designed to fuel your innovation initiatives, accelerate product development, and drive technological advancements.">
          Stay ahead of the curve in the fast-paced world of Hi-Tech
          & Innovations with our custom solutions, designed to fuel your
          innovation initiatives, accelerate product development, and drive
          technological advancements.
        </p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Artificial intelligence">Artificial intelligence</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Robotics">Robotics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Automation">Automation</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Blockchain">Blockchain</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Internet of Things (IoT)">Internet of Things (IoT)</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Software development">Software development</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Cybersecurity">Cybersecurity</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Technology startup">Technology startup</p>
        </div>
      </div>
    </div>

    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--green)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/education" i18n="@@eLearning & Education">eLearning & Education</h2>
          <button routerLink="/industries/education" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Transform the future of education with our eLearning & Education solutions, providing interactive platforms, personalized learning experiences, and data-driven insights to enhance student engagement and outcomes.">
          Transform the future of education with our eLearning & Education
          solutions, providing interactive platforms, personalized learning
          experiences, and data-driven insights to enhance student engagement
          and outcomes.
        </p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Online courses">Online courses</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Virtual classrooms">Virtual classrooms</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Learning platforms">Learning platforms</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Distance learning">Distance learning</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Interactive learning">Interactive learning</p>
        </div>
      </div>
    </div>

    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--red)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/media" i18n="@@Media & Entertainment">Media & Entertainment</h2>
          <button routerLink="/industries/media" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Engage audiences and captivate viewers with our Media & Entertainment solutions, offering immersive digital experiences, content management systems, and analytics tools to drive content creation, distribution, and monetization strategies.">
          Engage audiences and captivate viewers with our Media & Entertainment
          solutions, offering immersive digital experiences, content management
          systems, and analytics tools to drive content creation, distribution,
          and monetization strategies.
        </p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Movies">Movies</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Television">Television</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Music">Music</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Film industry">Film industry</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Games">Games</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Social media">Social media</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Concerts and performances">Concerts and performances</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Video games">Video games</p>
        </div>
      </div>
    </div>
    <div
      class="industry-block border-custom big"
      [style.borderColor]="'var(--orange)'"
    >
      <div class="industry-info">
        <div class="title">
          <h2 class="title-nav" routerLink="/industries/logistics" i18n="@@Logistics & Transportation">Logistics & Transportation</h2>
          <button routerLink="/industries/logistics" class="button-icon">
            <mat-icon svgIcon="arrow-up-right"></mat-icon>
          </button>
        </div>
        <p class="l normal text" i18n="@@Optimize your supply chain and transportation networks with our Logistics & Transportation solutions, delivering real-time visibility, operational efficiency, and cost savings across the entire logistics ecosystem.">
          Optimize your supply chain and transportation networks with our
          Logistics & Transportation solutions, delivering real-time visibility,
          operational efficiency, and cost savings across the entire logistics
          ecosystem.
        </p>
      </div>
      <div class="industry-tags">
        <div class="tag-block">
          <p class="l bold" i18n="@@Freight">Freight</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Supply chain">Supply chain</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Cargo transportation">Cargo transportation</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Railway logistics">Railway logistics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Maritime logistics">Maritime logistics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Air logistics">Air logistics</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Warehouse management">Warehouse management</p>
        </div>
        <div class="tag-block">
          <p class="l bold" i18n="@@Supply chain management">Supply chain management</p>
        </div>
      </div>
    </div>
  </div>
  <div class="contact-us">
    <app-contact-us></app-contact-us>
  </div>
</div>
